@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.root {
  padding-bottom: 60px;
}
.head {
  padding: 30px 5% 0;
  .title {
    text-align: center;
    margin-bottom: 10px;
    em {
      font-family: variable.$Freeman;
      display: block;
      font-size: 88%;
    }
  }
  .subtitle {
    margin-bottom: 10px;
    font-family: variable.$primaryFontBold;
    color: variable.$blackColor;
    font-size: 4.6vw;
  }
}
.tabs {
  display: flex;
  background-color: variable.$whiteColor;
  padding: 10px;
  margin-bottom: 5px;
  overflow: auto;
  .tab {
    span {
      display: block;
      white-space: nowrap;
      cursor: pointer;
      font-family: variable.$primaryFontSemiBold;
      font-size: 3.2vw;
      padding: 7px 40px;
      color: rgba($color: variable.$blackColor, $alpha: 0.4);
      border-bottom: 1px solid rgba($color: variable.$blackColor, $alpha: 0.4);
    }
    &.active,
    &:hover {
      span {
        color: variable.$primaryColor;
        border-bottom-color: variable.$primaryColor;
        border-bottom-width: 2px;
      }
    }
  }
}
.row {
  display: flex;
  flex-direction: column-reverse;
}
.cards {
  padding: 7px 10px;
  > div {
    display: flex;
    gap: 10px;
    > div {
      flex: 1;
      display: flex;
      padding: 20px 11px;
      align-items: center;
      gap: 11px;
      .text {
        flex: 1;
        color: variable.$whiteColor;
        font-size: variable.$size14;
        strong {
          font-size: 120%;
          display: block;
          font-family: variable.$Freeman;
        }
      }
      &:first-child {
        background-color: #faa0c8;
      }
      &:last-child {
        background-color: #cd8cdc;
        align-items: flex-end;
      }
    }
  }
}
.violetBox {
  background-color: variable.$primaryColor;
  padding: 15px 10px 15px 25px;
  border-radius: 0 0 15px 15px;
  margin: 0 10px;
  color: variable.$whiteColor;
  strong {
    font-size: variable.$size26;
    font-family: variable.$Freeman;
    line-height: 40px;
  }
  em {
    font-size: variable.$size48;
    font-family: variable.$Freeman;
    line-height: 48px;
  }
  span {
    font-size: variable.$size14;
    font-family: variable.$primaryFont;
  }
  .flex {
    display: flex;
    gap: 6px;
  }
}
@include mixin.mediaTabletSize() {
  .head {
    .title {
      em {
        display: inline-block;
        font-family: inherit;
        font-size: inherit;
      }
    }
    .subtitle {
      font-size: variable.$size20;
    }
  }
  .tabs {
    justify-content: center;
    .tab {
      span {
        font-size: variable.$size16;
      }
    }
  }
  .cards {
    padding-top: 0;
    padding-bottom: 20px;
    > div {
      gap: 20px;
      > div {
        flex-direction: column;
        padding: 15px 20px;
        > div {
          width: 100%;
        }
        &:last-child {
          flex-direction: column-reverse;
          align-items: center;
          img {
            width: 100%;
          }
        }
        &:first-child {
          border-top-left-radius: 15px;
          img {
            width: 75%;
            margin: auto;
          }
        }
        .text {
          strong {
            font-size: variable.$size36;
          }
        }
      }
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    .col {
      &:first-child {
        min-width: 335px;
      }
      &:last-child {
        width: calc(100vw - 380px);
      }
    }
  }
  .violetBox {
    border-bottom-right-radius: 0;
  }
}
@include mixin.mediaDesktopSize() {
  .row {
    padding-left: 10px;
    .col {
      &:first-child {
        min-width: 355px;
        width: 355px;
      }
    }
  }
  .violetBox {
    strong {
      font-size: variable.$size34;
      margin-bottom: 45px;
      display: block;
    }
    em {
      font-size: 80px;
      margin-bottom: 25px;
    }
    span {
      font-size: variable.$size20;
      display: block;
      padding-right: 55px;
    }
    .flex {
      flex-direction: column;
    }
  }
}
