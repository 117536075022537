@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.reviews {
  overflow: hidden;
}
.title {
  margin-bottom: 0;
}
.text {
  margin-bottom: 25px;
  font-family: variable.$primaryFontBold;
  color: variable.$primaryColor;
  font-size: 4.6vw;
}
.slider {
  [class*="slick-arrow"] {
    width: 36px;
    height: 36px;
    z-index: 10;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    top: 40vw;
    &:before {
      display: none;
    }
  }
  [class*="slick-next"] {
    right: 30px;
    background-image: url("/static/images/icons/arrow-slider-right-white.png");
  }
  [class*="slick-prev"] {
    left: 30px;
    background-image: url("/static/images/icons/arrow-slider-left-white.png");
  }
  [class*="slick-track"] {
    display: flex;
    [class*="slick-slide"] {
      height: auto;
      > div {
        height: 100%;
      }
    }
  }
  [class*="slick-dots"] {
    bottom: 28px;
    li {
      margin: 0;
      &[class*="slick-active"] {
        button {
          &::before {
            opacity: 0.25;
          }
        }
      }
      button {
        &::before {
          font-size: 10px;
          color: variable.$whiteColor;
          opacity: 1;
        }
      }
    }
  }
  .review {
    display: flex !important;
    flex-direction: column-reverse;
    color: variable.$whiteColor;
    height: 100%;
    .content {
      flex: 1;
      background-color: variable.$primaryColor;
      padding: 50px 30px 70px;
      .programType {
        background-color: variable.$orangeColor;
        display: inline-block;
        padding: 3px 6px;
        margin-left: 65px;
        margin-bottom: 4px;
        transform-origin: bottom left;
        transform: rotate(-4deg);
        font-family: variable.$Freeman;
        text-transform: uppercase;
      }
      .name {
        font-size: variable.$size20;
        font-family: variable.$primaryFontBold;
        color: variable.$orangeColor;
        strong {
          font-size: variable.$size34;
          font-family: variable.$CaveatBrush;
          color: variable.$whiteColor;
        }
      }
      .description {
        padding-left: 50px;
        font-size: variable.$size20;
        font-family: variable.$primaryFontSemiBold;
        margin-top: 5px;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
          background-image: url("/static/images/icons/left-quote.svg");
          background-repeat: no-repeat;
          background-size: contain;
          width: 34px;
          height: 27px;
          transform: scale(1, -1);
        }
        p {
          &:after {
            content: "";
            display: inline-block;
            z-index: 1;
            background-image: url("/static/images/icons/left-quote.svg");
            background-repeat: no-repeat;
            background-size: contain;
            width: 24px;
            height: 17px;
            transform: scale(-1, -1);
            margin-left: 3px;
            position: relative;
            top: 5px;
          }
        }
      }
    }
    .thumb {
      min-width: calc(50% - 130px);
      position: relative;
      > div {
        padding-top: 80%;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          object-fit: cover;
          object-position: top;
        }
      }
    }
  }
}
@include mixin.mediaTabletSize() {
  .text {
    color: variable.$blackColor;
    font-size: variable.$size20;
    br {
      display: none;
    }
  }
  .slider {
    width: calc(100% - 130px);
    [class*="slick-list"] {
      overflow: visible;
    }

    [class*="slick-arrow"] {
      top: 50%;
    }
    [class*="slick-next"] {
      right: -120px;
    }
    [class*="slick-prev"] {
      left: 40px;
    }
    [class*="slick-slide"]:nth-child(even) {
      .review {
        flex-direction: row;
      }
    }
    .review {
      flex-direction: row;
      .content {
        padding: 50px 30px 70px 100px;
        display: flex;
        align-items: center;
        .programType {
          margin-bottom: 0;
        }
        .description {
          max-width: 440px;
        }
      }
      .thumb {
        border-inline: 0.5px solid #ffffff;
        max-width: 500px;
        width: 33%;
        min-width: auto;
        > div {
          padding-top: 100%;
        }
      }
    }
  }
}
@include mixin.mediaDesktopSize() {
  .slider {
    width: calc(100% - 180px);
  }
}
